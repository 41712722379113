import { Reducer } from "./Reducer";
import { Action } from "./Action";
import { Store } from "./Store";

interface Subscription<TState> {
    call(a: TState): void;
    area: keyof TState | "";
}

interface ReducerSubscription<TState> {
    reducer: Reducer<any, any>;
    area: keyof TState | null;
}

export class BaseStore<TState> implements Store {
    public state: TState;
    private subscriptions: Subscription<TState>[];
    private reducerSubscriptions: ReducerSubscription<TState>[];
    constructor(initState: () => TState) {
        this.subscriptions = [];
        this.reducerSubscriptions = [];
        this.state = initState();
    }
    subscribe<K extends keyof TState>(area: K | null, call: (a: TState) => void) {
        let sub = { area, call };
        this.subscriptions.push(sub);
        return () => {
            this.subscriptions.splice(this.subscriptions.indexOf(sub), 1);
        };
    }
    addReducer<K extends keyof TState>(area: K | null, reducer: Reducer<TState[K] | TState, any>) {
        this.reducerSubscriptions.push({ area, reducer });
    }
    dispatch(action: Action) {
        let updatedAreas: (keyof TState | "")[] = [];
        for (let s of this.reducerSubscriptions) {
            let applyUpdateFn;
            if (s.area) {
                applyUpdateFn = (cb: (a: any) => any) => {
                    (<any>this.state[s.area]) = cb(this.state[s.area]);
                    updatedAreas.push(s.area);
                };
            }
            else {
                applyUpdateFn = (cb: (a: any) => any) => {
                    this.state = cb(this.state);
                    updatedAreas.push("");
                };
            }
            try {
                s.reducer.onDispatch(action, applyUpdateFn);
            }
            catch (err) {
                console.error(`Error while dispatching ${action.type}.`, err);
            }
        }
        if (updatedAreas.length) {
            for (let s of this.subscriptions) {
                if (!s.area || updatedAreas.indexOf(s.area) > -1) {
                    try {
                        s.call(this.state);
                    }
                    catch (err) {
                        console.error(`Error while updating on ${s.area || "global"} after ${action.type}.`, err);
                    }
                }
            }
        }
    }
}